@import "/src/style/mixin";

.wrapper {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(black, 0.7);

  &.active {
    display: block;
  }

  .spinner {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: calculatePcLargeVw(200px);
    height: calculatePcLargeVw(200px);
    animation: rotate 1s infinite linear;

    @include mobile{
      width: calculateMobileVw(100px);
      height: calculateMobileVw(100px);
    }

    @keyframes rotate {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}