@import '/src/style/mixin';

.wrapper {
  width: 100%;
  border-top: 1px solid rgba($lightGrey, 0.3);
  margin-top: calculatePcLargeVw(150px);
  margin-bottom: calculatePcLargeVw(50px);
  padding-top: calculatePcLargeVw(50px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    box-sizing: border-box;
    margin: calculateMobileVw(72px) 0;
    padding: calculateMobileVw(36px) calculateMobileVw(24px) 0;
  }

  .container {
    width: calculatePcLargeVw(1200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: calculatePcLargeVw(16px);
    font-family: 'OpenSans', serif;

    @include mobile {
      width: 100%;
    }

    .title {
      font-size: calculatePcLargeVw(24px);
      font-weight: 600;

      @include mobile {
        font-size: calculateMobileVw(15px);
      }
    }

    .address {
      font-size: calculatePcLargeVw(20px);
      @include mobile {
        font-size: calculateMobileVw(14px);
      }
    }

    .email {
      font-size: calculatePcLargeVw(20px);
      margin-top: calculatePcLargeVw(20px);

      a {
        text-decoration: none;
        color: $white;
      }

      .kor {
        font-family: 'Pretendard', serif;
      }

      @include mobile {
        margin-top: calculateMobileVw(20px);
        font-size: calculateMobileVw(14px);
      }
    }

    .copyright {
      width: 100%;
      text-align: center;
      margin-top: calculatePcLargeVw(40px);
      color: $lightGrey;

      @include mobile {
        margin-top: calculateMobileVw(40px);
        font-size: calculateMobileVw(14px);
      }
    }
  }
}