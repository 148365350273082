@import '/src/style/mixin';

.wrapper {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.5);

  &.active {
    display: block;
  }

  .container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: $darkGrey000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calculatePcLargeVw(20px);
    width: calculatePcLargeVw(400px);
    height: calculatePcLargeVw(200px);
    box-sizing: border-box;
    border-radius: 20px;

    font-family: 'Pretendard', serif;
    font-size: calculatePcLargeVw(30px);
    text-align: center;

    @include mobile {
      gap: calculateMobileVw(10px);
      width: calculateMobileVw(200px);
      height: calculateMobileVw(100px);

      font-size: calculateMobileVw(15px);
    }

    .btnModal {
      border-radius: 20px;
      padding: calculatePcLargeVw(10px) calculatePcLargeVw(25px);
      cursor: pointer;

      font-family: 'OpenSans', serif;
      font-size: calculatePcLargeVw(15px);
      text-align: center;

      @include mobile {
        padding: calculateMobileVw(5px) calculateMobileVw(20px);
        font-size: calculateMobileVw(10px);
      }
    }
  }
}