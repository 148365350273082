$black: #000000;
$black002: #0F0F10;
$white: #FFFFFF;
$darkGrey000: #212125;
$darkGrey001: #1A1A1D;
$darkGrey002: #646464;
$purple: #A55FF0;
$gradient: linear-gradient(113deg, #9747FF 1%, #00C2FF 100%);
$lightGrey: #BCBCBC;
$skyblue: #00C2FF;
$pink: #DC3388;

:export {
  black: $black;
  black002: $black002;
  white: $white;
  darkGrey001: $darkGrey001;
  darkGrey002: $darkGrey002;
  purple: $purple;
  gradient: $gradient;
  lightGrey: $lightGrey;
  skyblue: $skyblue;
  pink: $pink;
}