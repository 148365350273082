@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./Pretendard-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./Pretendard-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./Pretendard-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Pretendard';
    src: url('./Pretendard-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}