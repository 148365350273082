@import '/src/style/mixin';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-top: calculatePcLargeVw(124px);

  @include mobile {
    padding: calculateMobileVw(80px) calculateMobileVw(24px) 0;
  }

  .notificationTitle {
    font-weight: 600;
    font-size: calculatePcLargeVw(36px);
    background: linear-gradient(82.57deg, #00F2EA 2.88%, #FF004F 93.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    @include mobile {
      font-size: calculateMobileVw(28px);
    }
  }

  .notification {
    margin-top: calculatePcLargeVw(24px);
    font-family: 'Pretendard', serif;
    font-weight: 400;
    font-size: calculatePcLargeVw(24px);
    text-align: center;
    line-height: calculatePcLargeVw(48px);
    letter-spacing: 0.01em;
    color: $lightGrey;

    u {
      font-weight: 700;
      color: #ffc139;
    }

    @include mobile {
      margin-top: calculateMobileVw(24px);
      padding: 0 calculateMobileVw(7px);
      font-size: calculateMobileVw(14px);
      line-height: calculateMobileVw(32px);
    }
  }

  .agreeWrapper {
    margin-top: calculatePcLargeVw(16px);
    @include mobile {
      width: 100%;
      margin-top: calculateMobileVw(16px);
    }

    .agreeContainer {
      background: $darkGrey001;
      border-radius: calculatePcLargeVw(20px);

      @include mobile {
        border-radius: calculateMobileVw(20px);
      }

      .agreeContent {
        &.disabled {
          opacity: 0.5;
        }
      }

      .inputAgree {
        display: none;
      }

      .imgAgree {
        width: calculatePcLargeVw(32px);
        height: calculatePcLargeVw(32px);

        @include mobile {
          width: calculateMobileVw(24px);
          height: calculateMobileVw(24px);
        }
      }

      .labelAgree {
        width: calculatePcLargeVw(1200px);
        height: calculatePcLargeVw(104px);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: calculatePcLargeVw(16px);
        cursor: pointer;
        border-radius: calculatePcLargeVw(20px);

        font-family: 'Pretendard', serif;
        font-weight: 600;
        font-size: calculatePcLargeVw(24px);
        letter-spacing: 0.02em;
        color: $white;

        @include mobile {
          width: 100%;
          height: calculateMobileVw(64px);
          gap: calculateMobileVw(16px);
          border-radius: calculateMobileVw(20px);
          font-size: calculateMobileVw(14px);
        }
      }
    }
  }

  .btnConnectWalletWrapper {
    margin-top: calculatePcLargeVw(48px);

    @include mobile {
      width: 100%;
      margin-top: calculateMobileVw(48px);
    }

    .btnConnectWallet {
      width: calculatePcLargeVw(1200px);
      height: calculatePcLargeVw(104px);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calculatePcLargeVw(16px);
      background: $darkGrey001;
      border-radius: calculatePcLargeVw(20px);
      cursor: pointer;

      font-family: 'Pretendard', serif;
      font-weight: 600;
      font-size: calculatePcLargeVw(24px);
      line-height: calculatePcLargeVw(28px);

      @include mobile {
        width: 100%;
        height: calculateMobileVw(64px);
        gap: calculateMobileVw(16px);
        border-radius: calculateMobileVw(20px);
        font-size: calculateMobileVw(14px);
        line-height: calculateMobileVw(28px);
      }

      .imgConnectWallet {
        width: calculatePcLargeVw(40px);
        height: calculatePcLargeVw(30px);

        @include mobile {
          width: calculateMobileVw(30px);
          height: calculateMobileVw(22px);
        }
      }
    }
  }

  .txListWrapper {
    margin-top: calculatePcLargeVw(16px);
    @include mobile {
      width: 100%;
      margin-top: calculateMobileVw(16px);
    }

    .txListContainer {
      width: calculatePcLargeVw(1200px);
      background: $darkGrey001;
      border-radius: calculatePcLargeVw(20px);
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: calculatePcLargeVw(32px) calculatePcLargeVw(102px);

      @include mobile {
        width: 100%;
        border-radius: calculateMobileVw(20px);
        padding: calculateMobileVw(24px);
      }

      .title {
        width: 100%;
        font-family: 'Pretendard', serif;
        font-size: calculatePcLargeVw(24px);
        font-weight: 500;
        line-height: calculatePcLargeVw(24px);
        letter-spacing: -0.01em;

        @include mobile {
          font-size: calculateMobileVw(20px);
          line-height: calculateMobileVw(24px);
        }
      }

      .informationWrapper {
        margin-top: calculatePcLargeVw(32px);
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: calculatePcLargeVw(24px);

        @include mobile {
          margin-top: calculateMobileVw(16px);
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: calculateMobileVw(16px);
        }

        .informationContainer {
          display: flex;
          flex-direction: column;
          gap: calculatePcLargeVw(4px);
          color: $lightGrey;

          @include mobile {
            width: 100%;
            gap: calculateMobileVw(4px);
          }

          .informationTitle {
            font-family: 'Pretendard', serif;
            font-weight: 400;
            font-size: calculatePcLargeVw(20px);
            line-height: calculatePcLargeVw(28px);
            @include mobile {
              font-size: calculateMobileVw(14px);
              line-height: calculateMobileVw(28px);
            }
          }

          .inputInformation {
            box-sizing: border-box;
            background: $darkGrey000;
            border: 0.5px solid $darkGrey002;
            border-radius: calculatePcLargeVw(5px);
            padding: calculatePcLargeVw(6px) calculatePcLargeVw(16px);
            font-size: calculatePcLargeVw(20px);
            line-height: calculatePcLargeVw(28px);

            font-family: 'OpenSans', serif;
            font-weight: 400;

            @include mobile {
              border-radius: calculateMobileVw(5px);
              padding: calculateMobileVw(6px) calculateMobileVw(16px);
              font-size: calculateMobileVw(16px);
              line-height: calculateMobileVw(28px);
            }

            &::placeholder {
              color: $lightGrey;
            }

            &.inputAddress {
              color: $darkGrey002;
              width: calculatePcLargeVw(282px);

              @include mobile {
                width: 100%;
              }

              &.disabled {
                opacity: 0.5;
              }
            }

            &.inputName {
              width: calculatePcLargeVw(180px);
              color: $white;
              font-family: 'Pretendard', serif;

              @include mobile {
                width: 100%;
              }

              &:disabled {
                opacity: 0.5;
              }
            }
          }

          .contactWrapper {
            display: flex;
            gap: calculatePcLargeVw(8px);

            @include mobile {
              gap: calculateMobileVw(8px);
            }

            .selectCountryCode {
              background: $darkGrey000;
              width: calculatePcLargeVw(96px);
              padding: calculatePcLargeVw(4px) calculatePcLargeVw(16px);
              color: $lightGrey;
              border-radius: calculatePcLargeVw(5px);
              background: url('../assets/icons/Arrow_Down.png') no-repeat 100% 50%;
              background-size: calculatePcLargeVw(32px) calculatePcLargeVw(32px);
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;

              font-family: 'OpenSans', serif;
              font-size: calculatePcLargeVw(16px);

              @include mobile {
                width: calculateMobileVw(120px);
                padding: calculateMobileVw(4px) calculateMobileVw(16px);
                border-radius: calculateMobileVw(5px);
                background-size: calculateMobileVw(32px) calculateMobileVw(32px);
                font-size: calculateMobileVw(16px);
              }

              &::-ms-expand {
                display: none;
              }
            }

            .inputContact {
              width: calculatePcLargeVw(235px);
              color: $white;

              @include mobile {
                width: 100%;
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              &:disabled {
                opacity: 0.5;
              }
            }
          }
        }

        .btnSubmit {
          width: calculatePcLargeVw(123px);
          height: calculatePcLargeVw(40px);
          cursor: pointer;
          @include mobile {
            display: none;
          }
        }

        .moBtnSubmit {
          display: none;
          @include mobile {
            display: block;
            width: 100%;
            height: calculateMobileVw(42px);
            margin-top: calculateMobileVw(20px);
          }
        }
      }

      .txList {
        width: 100%;
        margin-top: calculatePcLargeVw(64px);

        @include mobile {
          margin-top: calculateMobileVw(56px);
        }

        .titleWrapper {
          width: 100%;
          display: flex;
          align-items: center;
          gap: calculatePcLargeVw(24px);
          padding-bottom: calculatePcLargeVw(24px);
          border-bottom: 1px solid $darkGrey002;

          @include mobile {
            gap: calculateMobileVw(8px);
            padding-bottom: calculateMobileVw(16px);
          }

          .title {
            color: $lightGrey;
            font-family: 'Pretendard', serif;
            font-weight: 600;
            font-size: calculatePcLargeVw(20px);
            line-height: calculatePcLargeVw(24px);
            letter-spacing: -0.01em;
            text-align: center;

            @include mobile {
              font-size: calculateMobileVw(14px);
              line-height: calculateMobileVw(24px);
            }

            &:nth-child(1) {
              flex: 1;
            }

            &:nth-child(2) {
              flex: 4;
            }

            &:nth-child(3) {
              flex: 8;
              @include mobile {
                flex: 3;
              }
            }
          }
        }

        .twWrapper {
          width: 100%;
          margin-top: calculatePcLargeVw(24px);
          display: flex;
          flex-direction: column;
          gap: calculatePcLargeVw(20px);

          @include mobile {
            margin-top: calculateMobileVw(16px);
          }

          .tx {
            width: 100%;
            display: flex;
            align-items: center;
            gap: calculatePcLargeVw(24px);

            @include mobile {
              gap: calculateMobileVw(8px);
            }

            div {
              font-family: 'OpenSans', serif;
              font-weight: 400;
              font-size: calculatePcLargeVw(20px);
              line-height: calculatePcLargeVw(24px);
              letter-spacing: -0.01em;

              @include mobile {
                font-size: calculateMobileVw(11px);
                line-height: calculateMobileVw(18px);
              }
            }

            .number {
              text-align: center;
              flex: 1;
            }

            .date {
              text-align: center;
              flex: 4;
            }

            .amount {
              text-align: right;
              padding-top: calculatePcLargeVw(8px);
              padding-bottom: calculatePcLargeVw(8px);
              padding-right: calculatePcLargeVw(16px);
              background: $darkGrey000;
              border: 0.5px solid $darkGrey002;
              border-radius: calculatePcLargeVw(5px);
              flex: 8;
              @include mobile {
                flex: 3;
              }
            }
          }
        }
      }
    }
  }
}

.borderGradient {
  border: 1px solid transparent;
  border-radius: calculatePcLargeVw(20px);
  background-image: linear-gradient(-90deg, #00F2EA, #FF004F);
  background-origin: border-box;
  background-clip: border-box;
  @include mobile {
    border-radius: calculateMobileVw(20px);
  }
}

.newLine {
  @include mobile {
    display: none;
  }
}

.modalWrapper {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.5);

  &.active {
    display: block;
  }

  .modalContainer {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: $darkGrey000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calculatePcLargeVw(20px);
    width: calculatePcLargeVw(400px);
    height: calculatePcLargeVw(200px);
    box-sizing: border-box;
    border-radius: 20px;

    font-family: 'Pretendard', serif;
    font-size: calculatePcLargeVw(30px);
    text-align: center;

    @include mobile {
      gap: calculateMobileVw(10px);
      width: calculateMobileVw(200px);
      height: calculateMobileVw(100px);

      font-size: calculateMobileVw(15px);
    }

    .btnModal {
      border-radius: 20px;
      padding: calculatePcLargeVw(10px) calculatePcLargeVw(25px);
      cursor: pointer;

      font-family: 'OpenSans', serif;
      font-size: calculatePcLargeVw(15px);
      text-align: center;

      @include mobile {
        padding: calculateMobileVw(5px) calculateMobileVw(20px);
        font-size: calculateMobileVw(10px);
      }
    }
  }
}