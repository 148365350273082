@import '/src/style/constants';
@import '/src/style/mixin';

body {
  font-family: 'OpenSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $black;
  color: $white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
}