@import '/src/style/_constants';

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin pc {
  @media (min-width: #{$mobile-width + 1px}) {
    @content;
  }
}